import axios from "axios";
const apiUrl = "https://new-leeds-academy.ardillalabs.cloud/api"; 

export const getProfitLoss = async (dateOrMonth) => {
return new Promise(async (resolve, reject) => {
    try {
    const config = {
        headers: {
        "Content-Type": "application/json",
        },
        withCredentials: true,
    };

    const response = await axios.get(
        `${apiUrl}/cashier-account/institute-report/profit-loss-calculation/${dateOrMonth}`,
        config
    );
    console.log(response)
    if (response.status === 200) {
        resolve(response);
    } else {
        reject(response);
    }
    } catch (err) {
    if (err.message === "Network Error") {
        reject({
        response: {
            data: {
            message: "Network Error",
            status: err.code,
            statusText: err.code,
            },
        },
        });
    } else {
        reject({
            response: {
                message: "There is an error in retrieving transactions.",
                status: err.code,
                statusText: err.code,            },
            });
    }
    }
});
};

export const downloadClassFeeReport = async (
  monthYear,
) => {
  return new Promise(async (resolve, reject) => {
    try {
      axios({
        method: 'get',
        url: `${apiUrl}/class-fee/institute-report/class-fee-institute-file-download/${monthYear}`,
        responseType: 'blob',
        headers: {
          Accept: 'text/csv',
        },
      }).then((response) => {
        if (response.status === 200) {
          downloadReport(response.data, `Class Fees ${monthYear}.csv`);
        }
      }).catch((err) => {
        reject({
          message: `This month(${monthYear}), there were no data found.`
        });
      });
    } catch (err) {
      if (err.message === "Network Error") {
        reject({
          response: {
            data: {
              message: "Network Error",
              status: err.code,
              statusText: err.code,
            },
          },
        });
      } else {
        reject(err);
      }
    }
  });
};

export const downloadTeacherPaymentReport = async (
  monthYear,
) => {
  return new Promise(async (resolve, reject) => {
    try {
      axios({
        method: 'get',
        url: `${apiUrl}/teacher-payment/institute-report/teacher-payment-institute-file-download/${monthYear}`,
        responseType: 'blob',
        headers: {
          Accept: 'text/csv',
        },
      }).then((response) => {
        if (response.status === 200) {
          downloadReport(response.data, `Teachers Payments ${monthYear}.csv`);
        }
      }).catch((err) => {
        reject({
          message: `This month(${monthYear}), there were no data found.`
        });
      });
    } catch (err) {
      if (err.message === "Network Error") {
        reject({
          response: {
            data: {
              message: "Network Error",
              status: err.code,
              statusText: err.code,
            },
          },
        });
      } else {
        reject(err);
      }
    }
  });
};

export const downloadCashierReport = async (monthYear) => {
  return new Promise(async (resolve, reject) => {
    try {
      axios({
        method: "get",
        url: `${apiUrl}/cashier-account/institute-report/cashier-account-file-download/${monthYear}`,
        responseType: "blob",
        headers: {
          Accept: "text/csv",
        },
      })
        .then(async (response) => {
          if (response.status === 200) {
            // const processedCsv = await processCsv(response.data);
            downloadReport(response.data, `Institute Transactions ${monthYear}.csv`);
          }
        })
        .catch((err) => {
          reject({
            message: `This month (${monthYear}), there were no data found.`,
          });
        });
    } catch (err) {
      if (err.message === "Network Error") {
        reject({
          response: {
            data: {
              message: "Network Error",
              status: err.code,
              statusText: err.code,
            },
          },
        });
      } else {
        reject(err);
      }
    }
  });
};

// Process the CSV to split Paid Amount into Debit and Credit columns
// const processCsv = async (blob) => {
//   return new Promise((resolve, reject) => {
//     const reader = new FileReader();
//     reader.onload = () => {
//       const csvText = reader.result;
//       const rows = csvText.split("\n").map((row) => row.split(",")); // Convert CSV to array

//       if (rows.length < 2) {
//         return reject("Invalid CSV format");
//       }

//       // Extract headers and find relevant column indexes
//       console.log(rows[0])
//       const headers = rows[0];
//       const debitCreditIndex = headers.indexOf("Debit/Credit");
//       const paidAmountIndex = headers.indexOf("Paid Amount");

//       console.log(headers.indexOf("Debit/Credit"))
//       console.log(debitCreditIndex)
//       if (debitCreditIndex === -1 || paidAmountIndex === -1) {
//         return reject("CSV headers not found");
//       }

//       // Add new columns
//       headers.push("Debit", "Credit");

//       const updatedRows = rows.map((row, index) => {
//         if (index === 0) return [...row, "Debit", "Credit"]; // Add new headers

//         console.log(row[debitCreditIndex].trim())
//         const debitCredit = row[debitCreditIndex]?.trim();
//         const paidAmount = parseFloat(row[paidAmountIndex]);

//         let debit = 0,
//           credit = 0;
//         if (debitCredit === "Debit") debit = paidAmount;
//         if (debitCredit === "Credit") credit = paidAmount;

//         return [...row, debit, credit];
//       });

//       // Convert back to CSV format
//       const updatedCsv = updatedRows.map((row) => row.join(",")).join("\n");
//       resolve(updatedCsv);
//     };

//     reader.onerror = () => reject("Error reading file");
//     reader.readAsText(blob);
//   });
// };

// Download the modified CSV
const downloadReport = (csvContent, filename) => {
  const blob = new Blob([csvContent], { type: "text/csv" });
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.style.display = "none";
  a.href = url;
  a.download = filename;
  document.body.appendChild(a);
  a.click();
  window.URL.revokeObjectURL(url);
};
