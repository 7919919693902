import {
    Table,
    Card,
    CardHeader,
    Modal,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    FormGroup,
    Row,
    Col,
    Spinner,
    CardBody,
} from "reactstrap";
import { useState, useEffect } from "react";
import SecondaryHeader from "components/Headers/SecondaryHeader";
import "../../assets/css/styles.css";
import { getUser } from "actions/Auth";
import { getProfitLoss } from "actions/ProfitCalculations";
import { downloadTeacherPaymentReport } from "actions/ProfitCalculations";
import { downloadClassFeeReport } from "actions/ProfitCalculations";
import { downloadCashierReport } from "actions/ProfitCalculations";

const TransactionReports = () => {

    const [allTransactions, setAllTransactiona] = useState(null);
    const [isAllTransactions, setIsAllTransactions] = useState(false);
    const [isLoadingTransactions, setLoadingTransactions] = useState(true);
    const [monthDropdownOpen, setMonthDropdownOpen] = useState(false);
    const [months, setMonths] = useState([]);

    const [isSuccess, setSuccess] = useState(false);
    const [isFailed, setFailed] = useState(false);
    const [isSuccessMessage, setSuccessMessage] = useState("");
    const [isErrorMessage, setErrorMessage] = useState("");
    const skip = 0;


    const date = new Date();
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    const newMonth = year + "-" + month;
    const newDate = year + "-" + month + "-" + day;
    const [selectedMonth, setSelectedMonth] = useState(newMonth);
    const [selectedDate, setSelectedDate] = useState(null);
    const [isMonth, setMonth] = useState(true);
    const [isDate, setDate] = useState(false);

    const getTransactionDetails = async (date) => {
        try {
            const response = await getProfitLoss(date);
            console.log(response)
            if (response.status === 200) {
                setAllTransactiona(response.data);
                setIsAllTransactions(true);
                setLoadingTransactions(false);
            } else {
                setIsAllTransactions(false);
                setLoadingTransactions(false);
            }
        } catch (error) {
            setIsAllTransactions(false);
            setLoadingTransactions(false);
        }
    }
    
    const getMonthList = async () => {
        const startDate = new Date();
        const endDate = new Date();
        startDate.setMonth(startDate.getMonth() - (12 - 1));
        const startMonth = startDate.getMonth();
        const endMonth = endDate.getMonth();
        const startYear = startDate.getFullYear();
        const endYear = endDate.getFullYear();
        const months = [];

        for (let year = startYear; year <= endYear; year++) {
            const monthStart = year === startYear ? startMonth : 0;
            const monthEnd = year === endYear ? endMonth : 11;

            for (let month = monthStart; month <= monthEnd; month++) {
                const monthString = `${year}-${(month + 1)
                    .toString()
                    .padStart(2, "0")}`;
                months.push({
                    label: monthString,
                    month: month + 1,
                    year: year,
                });
            }
        }
        setMonths(months);
    }

    const fetchData = async () => {
        try {
            await getMonthList();
            await getTransactionDetails(selectedMonth, skip);
        } catch (error) {
            setLoadingTransactions(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const filterBySearch = async (label) => {
        setMonth(true);
        setDate(false);
        setSelectedDate(null);
        setSelectedMonth(label);
        await getTransactionDetails(label);
    }

    const teacherPaymentReport = async () => {
        await downloadTeacherPaymentReport(selectedMonth);
    };

    const classFeeReport = async () => {
        await downloadClassFeeReport(selectedMonth);
    };

    const cashierReport = async () => {
        await downloadCashierReport(selectedMonth);
    };
    return (
        <>
            <SecondaryHeader />
            <div className="mt--5 container-fluid">
                <Modal
                    className="modal-dialog-centered modal-success"
                    isOpen={isSuccess}
                    toggle={() => setSuccess(false)}
                >
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">
                            {isSuccessMessage}
                        </h5>
                        <button
                            aria-label="Close"
                            className="close"
                            data-dismiss="modal"
                            type="button"
                            onClick={() => setSuccess(false)}
                        >
                            <span aria-hidden={true}>×</span>
                        </button>
                    </div>
                </Modal>
                <Modal
                    className="modal-dialog modal-danger"
                    isOpen={isFailed}
                    toggle={() => setFailed(false)}
                >
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">
                            {isErrorMessage}
                        </h5>
                        <button
                            aria-label="Close"
                            className="close"
                            data-dismiss="modal"
                            type="button"
                            onClick={() => setFailed(false)}
                        >
                            <span aria-hidden={true}>×</span>
                        </button>
                    </div>
                </Modal>
                {isLoadingTransactions ? (
                    <Card style={{ textAlign: "center" }}>
                        <Spinner className="m-10">Loading...</Spinner>
                    </Card>
                ) : (
                    <>
                        <Card className="mb-4 shadow" style={{ padding: "1rem" }}>
                            <CardHeader className="border-0">
                                <Row>
                                    <Col style={{ paddingLeft: "0rem" }}>
                                        <h3 className="mb-0 headerTitle">Profit Calculation</h3>
                                    </Col>
                                    <div className="justify-content-end" md="4" lg="4">
                                        <FormGroup className="mb-2">
                                            <Dropdown
                                                isOpen={monthDropdownOpen}
                                                toggle={() =>
                                                    setMonthDropdownOpen(!monthDropdownOpen)
                                                }
                                                className="filterButton"
                                                style={{ width: "232px !important" }}
                                            >
                                                <DropdownToggle caret>
                                                    {selectedMonth ? selectedMonth : "Filter by Month"}
                                                </DropdownToggle>
                                                {months && months.length > 0 ? (
                                                    <DropdownMenu>
                                                        {months.map((month) => (
                                                            <DropdownItem
                                                                key={month.label}
                                                                value={month.label}
                                                                onClick={() =>
                                                                    filterBySearch(
                                                                        month.label
                                                                    )
                                                                }
                                                            >
                                                                {month.label}
                                                            </DropdownItem>
                                                        ))}
                                                    </DropdownMenu>
                                                ) : null}
                                            </Dropdown>
                                        </FormGroup>
                                    </div>
                                </Row>
                            </CardHeader>
                            <CardBody style={{ margin: '0 auto' }}>
                                {isAllTransactions ?
                                    <Table className="align-items-center profit-table" responsive>
                                        <thead className="thead-light">
                                            <tr>
                                                <th scope="col">Type</th>
                                                <th scope="col" style={{ textAlign: 'right' }}>Amount (Rs.)</th>
                                                <th scope="col" style={{ textAlign: 'right' }}>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>Class Fee</td>
                                                <td style={{ textAlign: 'right' }}>{parseInt(allTransactions?.class_fee_total).toFixed(2)}</td>
                                                <td style={{ textAlign: 'right' }}><i className="fa-solid fa-download" onClick={classFeeReport} style={{ cursor: 'pointer', fontSize: '30px' }}></i></td>
                                            </tr>
                                            <tr>
                                                <td>Teachers' Payments</td>
                                                <td style={{ textAlign: 'right' }}>{parseInt(allTransactions?.teacher_payments_total).toFixed(2)}</td>
                                                <td style={{ textAlign: 'right' }}><i className="fa-solid fa-download" onClick={teacherPaymentReport} style={{ cursor: 'pointer', fontSize: '30px' }}></i></td>
                                            </tr>
                                            <tr>
                                                <td>Transaction Balance</td>
                                                <td style={{ textAlign: 'right' }}>{parseInt(allTransactions?.transaction_balance).toFixed(2)}</td>
                                                <td style={{ textAlign: 'right' }}><i className="fa-solid fa-download" onClick={cashierReport} style={{ cursor: 'pointer', fontSize: '30px' }}></i></td>
                                            </tr>
                                            <tr>
                                                <td style={{ fontSize: '24px', fontWeight: 'bold' }}>Profit / Loss</td>
                                                <td style={{ textAlign: 'right', fontSize: '24px', fontWeight: 'bold' }}>{parseInt(allTransactions?.profit_or_loss).toFixed(2)}</td>
                                                <td></td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                    :
                                    <Row style={{ textAlign: "center" }}>
                                        <img src={require("../../assets/img/brand/nodata.png")} className="noDataImage" alt="No Data Available" style={{ margin: "auto" }} />
                                    </Row>
                                }
                            </CardBody>
                        </Card>
                    </>
                )}
            </div >
        </>
    );
};

export default TransactionReports;
